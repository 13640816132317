import { faArrowLeft, faArrowUpShortWide, faCalculator, faCalendarAlt, faDollar, faHome, faLayerGroup, faPercent, faSackDollar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';

const Calculator = () => {
  const [capital, setCapital] = useState('');
  const [percentage, setPercentage] = useState('');
  const [period, setPeriod] = useState(1);
  const [depth, setDepth] = useState(15);
  const [dayEachWithdrawal, setDayEachWithdrawal] = useState(7);
  const [percentageAmountReinvested, setPercentageAmountReinvested] = useState(100);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [resultData, setResultData] = useState([]);
  const [calculationDone, setCalculationDone] = useState(false);
  const [totalProfit, setTotalProfit] = useState(0);

  const calculateInvestment = () => {
    const capitalNum = parseFloat(capital);
    const percentageNum = parseFloat(percentage);
    const periodNum = parseInt(period, 10);
    const depthNum = parseInt(depth, 10);
    const withdrawalInterval = parseInt(dayEachWithdrawal, 10);
    const reinvestmentPercentage = parseFloat(percentageAmountReinvested) / 100;
    const initialDate = new Date(`${startDate}T12:00:00`);

    if (isNaN(initialDate.getTime())) {
      alert("Please enter a valid start date.");
      return;
    }

    if (capitalNum < 0.01 || capitalNum > 9999999999) {
      alert("Capital must be between 0.01 and 9,999,999,999.");
      return;
    }

    if (percentageNum < 0.01 || percentageNum > 999999) {
      alert("Percentage must be between 0.01 and 999,999.");
      return;
    }

    if (periodNum < 1 || periodNum > 365) {
      alert("Period must be between 1 and 365.");
      return;
    }

    if (depthNum < 1 || depthNum > 180) {
      alert("Depth must be between 1 and 180.");
      return;
    }

    const results = [];
    let currentDate = new Date(initialDate);
    let currentCapital = capitalNum;
    let totalWithdrawn = 0;

    // Linha inicial
    results.push({
      date: currentDate.toLocaleDateString(),
      returnValue: "0.00",
      fee: "0.00",
      withdrawn: "0.00",
      reinvested: currentCapital.toFixed(2),
      capitalValue: currentCapital.toFixed(2),
    });

    for (let i = 0; i < depthNum; i++) {
      const returnValue = currentCapital * (percentageNum / 100);
      const fee = returnValue <= 20 ? 1 : returnValue * 0.05;
      const netReturn = returnValue - fee;
      let withdrawalAmount = 0;
      let netWithdrawal = 0;

      if ((i + 1) % withdrawalInterval === 0) {
        withdrawalAmount = currentCapital * (1 - reinvestmentPercentage);
        netWithdrawal = Math.max(withdrawalAmount - fee, 0);
        totalWithdrawn += netWithdrawal;
      }

      const reinvestedValue = currentCapital + netReturn - netWithdrawal;
      currentCapital = reinvestedValue;

      currentDate.setDate(currentDate.getDate() + periodNum);

      results.push({
        date: currentDate.toLocaleDateString(),
        returnValue: returnValue.toFixed(2),
        fee: fee.toFixed(2),
        withdrawn: netWithdrawal.toFixed(2),
        // reinvested: reinvestedValue.toFixed(2),
        reinvested: (returnValue - fee).toFixed(2),
        capitalValue: currentCapital.toFixed(2),
      });
    }

    setTotalProfit(totalWithdrawn.toFixed(2));
    setResultData(results);
    setCalculationDone(true);
  };

  const handleBack = () => {
    setCalculationDone(false);
  };

  const clearFields = () => {
    const clean = window.confirm("Clear all fields?");
    if (!clean) return;

    setCapital('');
    setPercentage('');
    setPeriod('');
    setDepth('');
    setDayEachWithdrawal(7);
    setPercentageAmountReinvested(100);
    setStartDate('');
  };

  return (
    <>
      <Row className="justify-content-center">
        {calculationDone && (
          <div className="result-screen">
            <h6 className="text-center my-4">Calculation result</h6>

            <p className='m-0'><FontAwesomeIcon icon={faSackDollar} /> Capital: <strong className='text-primary'>{capital} USDT</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faPercent} /> Percentage: <strong className='text-warning'>{percentage}% every {period} days</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faCalendarAlt} /> Withdrawal every: <strong>{dayEachWithdrawal} days</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faPercent} /> Reinvestment percentage: <strong>{percentageAmountReinvested}%</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faCalendarAlt} /> Start date: <strong>{startDate}</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faLayerGroup} /> Total results (depth): <strong className='text-danger'>{depth}</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faArrowUpShortWide} /> Total withdrawn: <strong className='text-success'>+{totalProfit} USDT</strong></p>

            <br />
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Return value</th>
                  <th>Fee</th>
                  <th>Reinvested</th>
                  <th>Withdrawn</th>
                  <th>Capital value</th>
                </tr>
              </thead>
              <tbody>
                {resultData.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.date}</td>
                    <td>{entry.returnValue} USDT</td>
                    <td>{entry.fee} USDT</td>
                    <td>{entry.reinvested} USDT</td>
                    <td>{entry.withdrawn} USDT</td>
                    <td>{entry.capitalValue} USDT</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Button variant="danger" className="w-100" onClick={handleBack}>
              <FontAwesomeIcon icon={faArrowLeft} /> Go back
            </Button>
          </div>
        )}

        {!calculationDone && (
          <Col md={6}>
            <h6 className='d-flex justify-content-between'>
              Investment calculator
              <Button variant='outline-warning' onClick={() => window.location.href = '/'}>
                <FontAwesomeIcon icon={faHome} />
              </Button>
            </h6>
            <Form id="investmentForm">
              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label><FontAwesomeIcon icon={faCalendarAlt} /> Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="capital">
                <Form.Label><FontAwesomeIcon icon={faSackDollar} /> Capital (USDT)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the capital"
                  value={capital}
                  onChange={(e) => setCapital(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="percentage">
                <Form.Label><FontAwesomeIcon icon={faPercent} /> Return percentage (%)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the return percentage"
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="period">
                <Form.Label><FontAwesomeIcon icon={faCalendarAlt} /> Period (days)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the period"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="depth">
                <Form.Label><FontAwesomeIcon icon={faLayerGroup} /> Depth of calculation</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the depth"
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="dayEachWithdrawal">
                <Form.Label><FontAwesomeIcon icon={faCalendarAlt} /> Withdrawal every (days)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter withdrawal interval"
                  value={dayEachWithdrawal}
                  onChange={(e) => setDayEachWithdrawal(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="percentageAmountReinvested">
                <Form.Label><FontAwesomeIcon icon={faPercent} /> Reinvestment percentage (%)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter reinvestment percentage"
                  value={percentageAmountReinvested}
                  onChange={(e) => setPercentageAmountReinvested(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="warning" className="w-100" onClick={calculateInvestment}>
                <FontAwesomeIcon icon={faCalculator} /> Calculate
              </Button>
              <Button variant="secondary" className="mt-2 w-100" onClick={clearFields}>
                <FontAwesomeIcon icon={faXmark} /> Clear fields
              </Button>
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Calculator;

/*Você não entendeu o que eu quiz dizer, então vou detalhar aqui:
Vamos supor que eu defina as seguintes propriedades:

StartDate: 2024-12-28
Capital: 300
Return percentage: 17.5
Period (Days): 7
Depth of calculation: 4
Withdrawal every (days): 7
Reinvestment percentage (%): 100

Period (Days) com valor 7 significa que a cada 7 dias terei o returnPercentage, nesse caso 17,5% a cada 7 dias.
Toda vez que faço o saque é cobrado a taxa (quando o valor do saque é menor que 20 é cobrado 1USDT de taxa, se maior que 20USDT é cobrado 5% do valor do saque), o dinheiro está comigo, daí eu posso decidir se vou ficar com uma parte ou reinvestir tudo. Nesse caso deve considerar a variável ReinvestmentPercentage, porque o resto que não for reinvestido eu pego pra mim.
Depth será a quantidade de resultados desejada, no caso 4 linhas na tabela.
Total withdrawn é a quantidade que eu saquei e ficou comigo (o restante de Reinvestment percentage).

Abaixo está o resultado que eu espero:

Date	Return value	Reinvested	Withdrawn	Capital value
28/12/2024	0.00 USDT	0.00 USDT	0.00 USDT	300.00 USDT
04/01/2025	49.88 USDT	49.88 USDT	0.00 USDT	349.88 USDT
11/01/2025	58.17 USDT	58.17 USDT	0.00 USDT	408.05 USDT
18/01/2025	67.84 USDT	67.84 USDT	0.00 USDT	486.67 USDT
25/01/2025	80.91 USDT	80.94 USDT	0.00 USDT	567.58 USDT
*/